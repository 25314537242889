<template>
    <div>
        <b-modal
            modal-class="modal-table modal-files"
            size="md"
            :title="isAdd ? 'Выберите или загрузите файл' : 'Файлы'"
            @show="openModalRowFiles"
            @hidden="resetModal"
            centered
            ref="modelRow"
        >
            <div class="modal-title-block" v-if="isAdd">
                Или выберите из прикрепленных файлов:
            </div>
            <b-form-group v-if="isAdd">
                    <b-form-select v-model="selectedCategory" :options="categoryFiles" text-field="name_ru"></b-form-select>
            </b-form-group>
            <div class="modal-search">
                <b-form-group>
                    <!-- Поле ввода текста для поиска -->
                    <b-form-input ref="searchTextField" v-model="searchText" placeholder="Поиск" type="text"/>
                    <!-- Кнопка очистки текста -->
                    <i class="icon icon-close clear-button" @click="() => { searchText = '' }"/>
                </b-form-group>
            </div>
            <div class="table-container modal-table-card mb-3">
                <div class="file-info" v-for="item in searchedFiles" :key="item.file_id">
                    <div class="d-flex align-items-center">
                        <b-form-checkbox
                            v-model="item.row_id"
                            name="checkbox-1"
                            :value="rowId"
                            :unchecked-value="null"
                            :disabled="!variantAttribute"
                        >
                        </b-form-checkbox>
                        <p class="name">
                            <img src="img/svg-icons/word.svg" alt="icon"
                                 v-if="item['file_name'].split('.')[item['file_name'].split('.').length-1] === 'docx'">
                            <img src="img/svg-icons/excel.svg" alt="icon"
                                 v-if="item['file_name'].split('.')[item['file_name'].split('.').length-1] === 'xls' || item['file_name'].split('.')[item['file_name'].split('.').length-1] === 'xlsx'">
                            <span style="cursor: pointer" @click="downloadRowFile(item['file_name'])">{{item['file_name']}}</span>
                        </p>
                        <p class="size">{{item['size']}}</p>
                    </div>
                </div>
            </div>

            <b-button
                size="sm"
                variant="primary"
                class="btn-selected"
            >
                <span>Выбрано {{count}} файла</span>
                <i class="icon icon-close ml-2"/>
            </b-button>
            <template #modal-footer>
                <div class="text-center" v-if="isAdd">
                    <b-button @click="fileUpload" variant="success" size="lg">Загрузить файл</b-button>
                    <b-button @click="saveRowFiles" variant="primary" size="lg">Загрузить</b-button>
                </div>
                <div class="text-center" v-else>
                    <b-button @click="saveRowFiles" variant="success" size="lg">Сохранить</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { Ax } from '@/utils';

export default {
    name: "modal-attach",
    props: ['isAdd', 'header', 'rowFilesInput', 'variantAttribute'],
    data() {
        return {
            selectedCategory: null,
            categoryFiles: [
                { value: null, name_ru: 'Выберите категорию файла', disabled: true },
                { value: '1001', name_ru: 'Договора' },
                { value: '1002', name_ru: 'Коммерческие предложения' },
                { value: '1003', name_ru: 'Акты' },
                { value: '1004', name_ru: 'Сметы' },
                { value: '1005', name_ru: 'Планы' },
                { value: '1006', name_ru: 'Заключения' },
                { value: '1007', name_ru: 'Расчеты' }
            ],
            rowId: null,
            searchText: '',
            rowFiles: [],
        }
    },
    created() {
        // console.log(this.variantAttribute);
    },
    methods: {
        openModalRowFiles() {
            this.$refs.modelRow.show();
        },
        openModalRowAddFilesByRowId(rowId) {
            this.$refs.modelRow.show();
            this.rowId = rowId;
        },
        resetModal() {
            this.$refs.modelRow.hide();
            this.selectedCategory = null;
            this.rowFiles = [];
            this.rowId = null;
        },
        async saveRowFiles() {
            let num_attach_files = 0;
            this.rowFiles.forEach(file => {
                if (file['row_id'] !== null) {
                    num_attach_files += 1
                }
            });
            const return_object = {
                isAdd: false,
                row_files: this.rowFiles,
                num_attach_files: num_attach_files
            };
            this.$emit('toggleIsAdd', return_object);
            this.resetModal();
        },
        fileUpload() {
            this.$emit('fileUpload', this.rowId);
        },
        addNewFiles(files) {
            this.rowFiles = [...this.rowFiles, ...files];
        },
        delFile(fileId) {
            this.rowFiles.forEach((file, index) => {
                if (file.file_id === fileId) {
                    this.rowFiles.splice(index, 1);
                }
            });
        },
        downloadRowFile(filename) {
            const that = this;
            Ax(
                {
                    url: '/api-py/download-file/' + filename,
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.makeToast('danger', 'Ошибка скачивания', error.toString());
                }
            );
        }, // скачать файл
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
    },
    watch: {
        rowId() {
            this.rowFiles = JSON.parse(JSON.stringify(this.rowFilesInput.sort((a, b) => b.row_id - a.row_id)));
        }
    },
    computed: {
        count() {
            let count = 0;
            this.rowFiles.forEach((val) => {
                if (val.row_id) {
                    count++;
                }
            });

            return count;
        },
        searchedFiles() {
            if (this.isAdd) {
                const list = this.rowFiles.filter(file => this.selectedCategory === file.file_type);
                return list.filter(file => file.file_name.includes(this.searchText));
            } else {
                return this.rowFiles.filter(file => file.file_name.includes(this.searchText));
            }
        }
    }
}
</script>

<style scoped>

</style>