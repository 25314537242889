<template>
        <div class="actions-tab actions-tab-budget-forms">
            <budget-attach-file/>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret :disabled="isReportUploading || progress != 100" ref="dropdown" @shown="adjustDropdownMenuStyle">
                <template #button-content>
                    <div class="btn-download">
                        <div>
                    <span class="left">
                        <b-spinner v-if="isReportUploading" variant="success" small label="Small Spinner"></b-spinner>
                        <i v-if="!isReportUploading" class="icon icon-download"></i> Скачать
                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="onClick(false)">Форма {{ form.code }}</b-dropdown-item>
                <b-dropdown-item @click="onClick(true, true)" :disabled="$store.state.isDownloadButtonEnabled">
                        {{$store.state.isDownloadButtonEnabled ? $t("modules.budget.budget_request.uploadIsProgress") : $t("modules.budget.budget_request.batchUploadGuButton") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="onClick(true, false)" :disabled="$store.state.isDownloadButtonEnabled" v-if="guListLen > 1">
                        {{$store.state.isDownloadButtonEnabled ? $t("modules.budget.budget_request.uploadIsProgress") : $t("modules.budget.budget_request.batchUploadAbpButton") }}
                    </b-dropdown-item>
            </b-dropdown>
        </div>
</template>

<script>
import BudgetAttachFile from "./budget-attach-file";

export default {
    name: 'FormsSaveBtn',
    components: { BudgetAttachFile, },
    props: {
        progress: {
            type: Number,
            required: true,
            defaut: 0
        },
        isReportUploading: {
            type: Boolean,
            required: true,
            defaut: false
        },
        form: {
            type: Object,
            required: true,
            defaut: { 
                code: ''
                }
        },
        guListLen: {
            type: Number,
            defaut: 0
        }
    },

    methods: {
        onClick(batch = false, oneGu = false) {
            this.$emit('checkSignatories', batch, oneGu);
            if (batch) {
                this.$emit('downloadBatchReports', oneGu);
            } else {
                this.$emit('downloadRep');
            }
        },

        adjustDropdownMenuStyle() {
            const dropdownMenu = this.$refs.dropdown.$el.querySelector('.dropdown-menu');
            if (dropdownMenu) {
                dropdownMenu.style.transform = 'translate3d(-115px, 30px, 0px)';
            }
        }, // смещение дропдауна скачивания отчетев для форм 02-159-1 и -2

    },
}
</script>

<style scoped>
.spinner-border-sm {
    width: 1rem !important;
}
</style>